










import Component from 'vue-class-component'
import Vue from "vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import PlusCircleIcon from '@/assets/icons/heroicons/PlusCircleIcon.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: {SygniRoundedButton, GenproxButton},
})
export default class FundsEmptyTable extends Vue {
  PlusCircleIcon = PlusCircleIcon;
  goToAddNewFund(): void {
    this.$router.push({name: 'add-new-fund', params: { useType: 'AIFM-ADD-FUND' }});
  }
}

